<template>
  <div
    class="fissionBoxAll"
    :class="{ fissionBoxAll_style: is_electronics != 2 }"
  >
    <div id="membership" v-if="is_electronics == 2">
      <div class="heardermembership">
        <div class="tu">
          <img src="../../assets/img/1.4.0.2/icon_dzzl@2x.png" alt="" />
        </div>
        <!-- <div class="tu2" v-if="list.status == 2">
          <img :src="list.src" alt="" />
        </div>

        <div class="tu3" v-if="list.status == 3">
          <img :src="list.src" alt="" />
        </div> -->
        <div class="right">
          <div class="right_font1">电子资料</div>
          <div class="right_font2">
            电子资料是指教学课程相关的课件、材料、作业、答案、试卷等电子文件，可以将这些资料以商品的形式进行售卖或免费赠送，扩大网校的内容运营品类，增加变现能力，持续提高用户粘性。
          </div>
          <div class="right_button">
            <el-button
              type="primary"
              size="medium"
              @click="kaitong"
              style="width: 112px"
            >
              立即开通
            </el-button>
            <div class="price">
              ￥399
              <span
                class="red"
                style="font-size: 14px; marign-left: 5px; font-weight: bold"
              >
                /年
              </span>
            </div>
            <div class="strick">￥799</div>
          </div>
        </div>
      </div>
      <noOpen :list="list"></noOpen>

      <openingfunction
        v-if="status2"
        :dialogstatus.sync="status2"
      ></openingfunction>
    </div>
    <!--头部-->
    <template v-else-if="$route.name == 'courseElectronics'">
      <div class="header">
        <div class="boxTest">
          <div class="c1" @click="editActivity" v-if="newBtn">
            <p>新建电子资料</p>
          </div>
        </div>
        <div class="storeSelect">
          <el-select v-model="post.status" placeholder="全部状态">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>

        <div class="storeSelect_2">
          <el-select v-model="post.is_show" placeholder="全部类型">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>

        <div class="input">
          <el-input
            placeholder="输入资料名称搜索"
            v-model="post.search"
            class="input-with-select"
          >
            <!-- 搜索 -->
          </el-input>
        </div>
      </div>

      <div class="contentVideo">
        <div class="rightMenu">
          <!-- 右侧内容 列表- 分页 -->
          <div class="rightCon">
            <pagination2
              :option="post"
              url="/electronicsData/electronicsList"
              ref="childFissionData"
            >
              <template v-slot:default="{ tableData }">
                <el-table
                  :data="tableData"
                  @sort-change="sortChange"
                  max-height:200
                  :header-cell-style="{
                    background: 'rgba(245,245,245,1)',
                    color: '#333333',
                  }"
                >
                  <el-table-column prop="name" label="资料" min-width="205">
                    <template slot-scope="scope">
                      <div class="titleActivitName">
                        <div class="left">
                          <img
                            :src="
                              scope.row.img_url
                                ? scope.row.img_url
                                : require('../../assets/img/1.4.0.2/img_mrfm@2x.png')
                            "
                            alt=""
                          />
                        </div>
                        <div class="right">
                          <p :title="scope.row.name">{{ scope.row.name }}</p>
                          <div class="imgBox">
                            <div>
                              <img
                                :src="
                                  scope.row.is_show == 1
                                    ? require('../../assets/img/1.4.0.2/icon_dg备份 3@2x.png')
                                    : require('../../assets/img/1.4.0.2/icon_cw备份 7@2x.png')
                                "
                                alt=""
                              />
                              <p>
                                {{
                                  scope.row.is_show == 1
                                    ? '展示到网校'
                                    : '不展示到网校'
                                }}
                              </p>
                            </div>
                            <div>
                              <img
                                :src="
                                  scope.row.status == 1
                                    ? require('../../assets/img/1.4.0.2/icon_dg备份 3@2x.png')
                                    : require('../../assets/img/1.4.0.2/icon_cw备份 7@2x.png')
                                "
                                alt=""
                              />
                              <p>
                                {{
                                  scope.row.status == 1 ? '已上架' : '已下架'
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="资料数量">
                    <template slot-scope="scope">
                      <p>{{ scope.row.count }}</p>
                    </template>
                  </el-table-column>

                  <el-table-column label="售价">
                    <template slot-scope="scope">
                      <div class="priceBox">
                        <p
                          :title="
                            scope.row.price_type == 1
                              ? scope.row.price
                              : scope.row.price_type == 2
                              ? '免费'
                              : '密码:' + scope.row.pwd
                          "
                        >
                          {{
                            scope.row.price_type == 1
                              ? scope.row.price
                              : scope.row.price_type == 2
                              ? '免费'
                              : '密码:' + scope.row.pwd
                          }}
                        </p>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="累计收入(元)">
                    <template slot-scope="scope">
                      <p>
                        {{ scope.row.sales_amount }}
                      </p>
                    </template>
                  </el-table-column>

                  <el-table-column label="累计订单(笔)">
                    <template slot-scope="scope">
                      <p>
                        {{ scope.row.sales_num }}
                      </p>
                    </template>
                  </el-table-column>

                  <el-table-column label="创建人">
                    <template slot-scope="scope">
                      <p>
                        {{ scope.row.uname }}
                      </p>
                    </template>
                  </el-table-column>

                  <el-table-column prop="proportion" label="创建时间">
                    <template slot-scope="scope">
                      <div class="titleActivit">
                        <p>
                          {{
                            scope.row.c_time
                              | formatTimeStamp('yyyy-MM-dd hh:mm')
                          }}
                        </p>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="操作" min-width="110">
                    <template slot-scope="scope">
                      <div class="mouseRight">
                        <div class="btns">
                          <span
                            v-if="managerBtn"
                            type="text"
                            class="text"
                            style="color: #0aa29b"
                            @click="goEditor(scope.row.ed_id)"
                          >
                            管理
                          </span>
                          <span class="span_1" v-if="managerBtn">|</span>
                          <span
                            class="text"
                            @click="getShareInfo(scope.row.ed_id)"
                          >
                            分享
                          </span>
                          <template>
                            <span class="span_1">|</span>
                            <el-dropdown>
                              <span class="text">更多</span>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-if="eidtBtn">
                                  <p
                                    :class="{ disabled: scope.row.status == 3 }"
                                    @click="editActivity(scope.row.ed_id)"
                                  >
                                    编辑
                                  </p>
                                </el-dropdown-item>
                                <el-dropdown-item v-if="updownBtn">
                                  <p
                                    @click="
                                      open(scope.row.ed_id, scope.row.status)
                                    "
                                  >
                                    {{
                                      scope.row.status == 1 ? '下架' : '上架'
                                    }}
                                  </p>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                  <p
                                    @click="
                                      isShow(scope.row.ed_id, scope.row.is_show)
                                    "
                                  >
                                    {{
                                      scope.row.is_show == 1
                                        ? '不展示到网校'
                                        : '展示到网校'
                                    }}
                                  </p>
                                </el-dropdown-item>
                                <el-dropdown-item v-if="delBtn">
                                  <p @click="delActivity(scope.row.ed_id)">
                                    删除
                                  </p>
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </el-dropdown>
                          </template>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </pagination2>
            <!--分页-->
          </div>
        </div>
      </div>
    </template>
    <router-view></router-view>
    <!--分享弹窗-->
    <sharePop
      v-if="shareId"
      @closeFn="toggleShareId"
      :shareInfo="shareInfo"
      type="2"
    ></sharePop>
  </div>
</template>
<script>
import noOpen from '@/components/copyrightprotect/noOpen' //功能介绍
import openingfunction from '@/components/copyrightprotect/openingfunction'
import bg from '@/mixin/background'
import sharePop from '@/components/coursePop/sharePop'
import API from '@/request/api'
export default {
  name: 'fissionBoxAll',
  components: {
    sharePop,
    openingfunction,
    noOpen,
  },

  mixins: [bg],
  computed: {
    is_electronics() {
      return this.$store.state.userInfo.is_electronics
    },

    //新建
    newBtn() {
      return this.$store.state.roots.includes(195)
    },

    //编辑
    eidtBtn() {
      return this.$store.state.roots.includes(196)
    },

    //管理
    managerBtn() {
      return this.$store.state.roots.includes(197)
    },

    //上下架
    updownBtn() {
      return this.$store.state.roots.includes(198)
    },

    //删除
    delBtn() {
      return this.$store.state.roots.includes(199)
    },
  },
  data() {
    return {
      list: {
        function_tu: require('@/assets/img/1.4.0.2/img_hyjs@2x.png'),
      },
      status2: false, //客服弹窗
      shareInfo: {},
      shareId: '',
      options: [
        {
          value: '0',
          label: '全部状态',
        },
        {
          value: '1',
          label: '已上架',
        },
        {
          value: '2',
          label: '已下架',
        },
      ],
      options2: [
        {
          value: '0',
          label: '全部类型',
        },
        {
          value: '1',
          label: '展示到网校',
        },
        {
          value: '2',
          label: '不展示到网校',
        },
      ],
      tableData: [],
      post: {
        status: '0',
        is_show: '0',
        search: '',
        sort: '',
        order: '',
      },
    }
  },

  created() {
    this.getData()
  },

  methods: {
    kaitong() {
      this.status2 = true
    },
    // 显示分享弹窗页面
    toggleShareId() {
      this.shareId = ''
    },
    // 获取分享资料数据
    getShareInfo(course_id) {
      const self = this
      self.$http({
        name: API.coursePosterShare_Api.name,
        url: API.coursePosterShare_Api.url,
        data: {
          ed_id: course_id,
          type: '2',
        },
        callback({ code, data }) {
          if (code == 200) {
            self.shareId = course_id
            self.shareInfo = data
          }
        },
      })
    },
    //点击查看
    goEditor(id) {
      // console.log(id)
      this.$router.push({
        path: '/courseElectronics/editor/' + id,
      })
    },
    isShelves(type, id, status) {
      //1 上/下架    2展示/不展示到网校
      this.$http({
        url: '/electronicsData/isShelves',
        data: {
          type: type,
          ed_id: id,
          status: status,
        },
        callback: (info) => {
          this.$root.prompt({
            msg: info.info,
            type: 'success',
          })
          this.$refs.childFissionData.reset()
        },
      })
    },
    isShow(id, status) {
      //展示网校开关
      if (status == 1) {
        this.$confirm(
          '隐藏后不会在网校显示资料信息，只可通过分享的链接、二维码进入查看！',
          '不展示到网校',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(() => {
          this.isShelves(2, id, 2)
        })
      } else {
        this.isShelves(2, id, 1)
      }
    },
    open(id, status) {
      if (status == 1) {
        this.$confirm(
          '下架后已购买的学员仍可以查看资料，未购买的学员将无法购买，确认下架吗？',
          '下架资料',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(() => {
          this.isShelves(1, id, 2)
        })
      } else {
        this.isShelves(1, id, 1)
      }
    },

    editActivity(id) {
      //点击新建资料编辑
      //检测id数据类型 新建跳转id传new
      if (typeof id == 'string') {
        this.$router.push({
          path: '/courseElectronics/newData/' + id,
        })
      } else {
        this.$router.push({
          path: '/courseElectronics/newData/' + 'new',
        })
      }
    },

    delActivity(id) {
      //删除
      this.$confirm(
        '删除后，已购买资料的学员自动失效，暂不支持还原资料和订单！',
        '删除资料',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        this.$http({
          url: '/electronicsData/isDel',
          data: {
            ed_id: id,
          },
          callback: () => {
            this.$root.prompt({
              msg: '删除成功',
              type: 'success',
            })
            this.$refs.childFissionData.reset()
          },
          error: () => {
            this.$root.prompt('删除失败')
          },
        })
      })
    },

    getData() {},
    // 排序
    sortChange(value) {
      // console.log(value.prop)
      var sort = 0
      var order = 0
      switch (value.order) {
        case null:
          break
        case 'descending':
          sort = 2
          break
        case 'ascending':
          sort = 1
          break
      }
      switch (value.prop) {
        case null:
          break
        case 'Share':
          order = 1
          break
        case 'dealOrder':
          order = 2
          break
        case 'profit':
          order = 3
          break
        case 'commission':
          order = 4
          break
      }
      this.post.order = order
      this.post.sort = sort
    },
  },
}
</script>
<style lang="scss" scoped>
#membership {
  margin: 20px;
}

::v-deep #noOpen {
  margin: 0;
}

::v-deep .introduction {
  margin-top: 0;
}

.heardermembership {
  background-color: #fff;
  padding: 20px;
  display: flex;
  margin-bottom: 20px;
  // border-bottom: 20px solid rgb(245, 245, 245);

  .tu,
  .tu2,
  .tu3 {
    width: 131px;
    height: 131px;
    background: #fff0dc;

    img {
      margin: 28px auto 0;
      width: 80px;
      height: 72px;
    }
  }

  .tu2 {
    background: #ff6c32;

    img {
      margin-top: 26px;
    }
  }

  .tu3 {
    background-color: #30b031;

    img {
      margin-top: 26px;
    }
  }

  .right {
    width: calc(100% - 161px);
    margin-left: 30px;

    .right_font1 {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 18px;
    }

    .right_font2 {
      height: 40px;
      margin-top: 14px;
      margin-bottom: 21px;
      font-size: 13px;
      color: #808080;
      line-height: 20px;
    }

    .right_button {
      display: flex;
      align-items: flex-end;

      .price {
        font-size: 18px;
        font-weight: bold;
        color: #ff3535;
        margin-left: 24px;
        margin-right: 12px;
      }

      .strick {
        font-size: 13px;
        text-decoration: line-through;
        color: #808080;
        line-height: 13px;
      }
    }
  }
}

::v-deep .el-tabs__nav-wrap:after {
  height: 1px;
}

::v-deep .el-tabs__nav-scroll {
  padding: 0 20px;
}

.priceBox {
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

p {
  color: #333;
}

.disabled {
  color: #c0c4cc;
  cursor: not-allowed !important;
  opacity: 0.6;
}

::v-deep.el-dropdown-menu {
  .el-button--text {
    color: none;
  }

  width: 136px;

  p {
    width: 115px;
  }
}

// .disabled {
//   pointer-events: none;
//   cursor: default;
//   opacity: 0.6;
// }

.el-dialog__body {
  .el-select {
    width: 100%;
  }
}

.contentVideo {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;

  .rightMenu {
    flex: 1;
    height: 100%;
    overflow-x: hidden;
    padding: 0px 20px 0px 20px;

    .titleActivitName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;

      .left {
        img {
          width: 68px;
          height: 38px;
        }
      }

      .right {
        margin-left: 10px;
        width: 100%;

        .right > p {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #333333;
        }

        .imgBox {
          display: flex;
          justify-content: space-between;
          width: 55%;

          img {
            width: 8px;
            height: 7px;
            margin-top: 4px;
            margin-right: 3px;
          }

          p {
            height: 12px;
            font-size: 12px;
            font-family: MicrosoftYaHei;
            color: #666666;
            line-height: 12px;
          }

          div {
            display: flex;
          }
        }
        .imgBox div:nth-child(2) {
          margin-left: 10px;
        }
      }

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.fissionBoxAll_style {
  background: #fff;
  margin: 20px 20px;
  border-top: 1px solid #fff;
}

::v-deep .fissionBoxAll .el-table {
  padding: 0 20px;
}

.header {
  display: flex;
  margin-bottom: 20px;
  min-width: 900px;
  margin-top: 20px;
  padding: 0 20px 0 20px;

  .uploading {
    width: 100px;
    height: 36px;
    background-color: #0aa29b;
    border-radius: 4px;
    text-align: center;
    line-height: 36px;
    color: #fff;
    font-size: 14px;
    // margin-top: 20px;
    margin-left: 20px;
    margin-right: 30px;
    cursor: pointer;
  }

  .boxTest {
    flex: 1;

    // margin-top: 20px;
    .c1 {
      flex: 1;
      width: 100px;
      height: 40px;
      background: #0aa29b;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;

      p {
        height: 14px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 40px;
      }
    }
  }

  .storeSelect {
    width: 160px;
    height: 36px;
    border-radius: 4px;
    margin-right: 20px;
  }

  .storeSelect_2 {
    width: 160px;
    height: 36px;
    border-radius: 4px;
  }

  .input {
    margin-left: 20px;
  }
}

.extensionHoverBox {
  p {
    width: 420px;
    height: 54px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #4a4a4a;
    line-height: 18px;
  }

  img {
    width: 297px;
    height: 416px;
    margin-left: 49px;
    margin-top: 10px;
  }
}

.mouseRight {
  width: 120px;

  span {
    color: #0aa29b;
  }

  .text {
    cursor: pointer;
  }

  .span_1 {
    margin: 0 2px;
  }
}

// .btns {
//   @extend %btns;
//   position: relative;
// }

// ::v-deep .el-button--text{
//     color: #606266;
// }
</style>
